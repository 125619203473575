import React from "react";
import Layout from "./../components/Layout";
import CommunityHero from "./../components/CommunityHero";
import CommunityFilter from "./../components/CommunityFilter";
import Map from "./../components/Map";
import MapNew from "../components/MapNew";
import CommunityMapToggle from "./../components/CommunityMapToggle";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Location } from "@reach/router";

// const hideCommunityFilter = false;
const hideCommunityFilter = true;

const MapPage = () => {
  const [currentTypes, setCurrentTypes] = React.useState([]);
  const communityQuery = useStaticQuery(graphql`
    query {
      craftDiscoverTheAvenueDiscoverTheAvenueEntry {
        title
        discoverCallout
      }
      allCraftCommunityCommunityMemberEntry(
        filter: {
          isDraft: { eq: false }
          communityIsThisAnAlserkalProperty: { eq: false }
        }
      ) {
        nodes {
          id
          communityMemberSlug
          communityMemberType
          slug
          title
        }
      }
    }
  `);
  // const communityMembers =
  //   communityQuery.allCraftCommunityCommunityMemberEntry.nodes;
  const {
    title,
    discoverCallout,
  } = communityQuery.craftDiscoverTheAvenueDiscoverTheAvenueEntry;


  return (
    <Location>
      {({ location }) => {
        return (
          <Layout
            backgroundColor={"var(--white)"}
            title={title}
            SEODescription={discoverCallout}
          >
            <div>
              <CommunityHero topLine={title} text={discoverCallout} />
              <CommunityMapToggle isMap />
              {hideCommunityFilter ? null : (
                <CommunityFilter
                  selected={(e) => {
                    // console.log("selected came back");
                    setCurrentTypes(e);
                  }}
                />
              )}
              
              <MapNew 
                useFakeMap={false}
                currentTypes={currentTypes}
                goToLocation={
                  location.search.indexOf("?location=") > -1
                    ? decodeURI(location.search.split("?location=")[1])
                    : ""
                }
                showCommunityMember={(e) => {
                  navigate(`/community/${e}`);
                }}
              />
            </div>
          </Layout>
        );
      }}
    </Location>
  );
};

export default MapPage;
